<template>
  <div class="homepage-card">
    <div class="homepage-card__image">
      <router-link v-if="path" @click="routingEvent" :to="{ name: path, query: pathQuery }" :data-text="tileText">
        <img loading="lazy" :src="src" :alt="alt" />
      </router-link>

      <a v-else @click="routingEvent" rel="noopener" target="_blank" :href="url" :data-text="tileText">
        <img loading="lazy" :src="src" :alt="alt" />
      </a>
    </div>
    <div class="homepage-card__text">
      <h3 class="homepage-card__text__label" v-if="!path && !url">{{ label }}</h3>
      <h3 v-else>
        <router-link v-if="path" class="homepage-card__button button-link" :to="{ name: path, query: pathQuery }"
          >{{ labelWithoutLastWord }}
          <span class="wrap-last-two-words">
            {{ labelLastWord }} <arrow class="homepage-card__arrow arrow"></arrow
          ></span>
        </router-link>
        <a v-else class="homepage-card__button button-link" :href="url" rel="noopener" target="_blank"
          >{{ labelWithoutLastWord }}
          <span class="wrap-last-two-words">
            {{ labelLastWord }} <arrow class="homepage-card__arrow arrow"></arrow></span
        ></a>
      </h3>
      <slot />
    </div>
  </div>
</template>

<script>
import arrow from '@/assets/images/Global/arrow.svg';

export default {
  name: 'homepage-card',
  components: {
    arrow,
  },
  props: {
    label: { type: String, required: true },
    src: { type: String, required: true },
    alt: { type: String, required: true, default: '' },
    path: { type: String, required: false },
    pathQuery: { type: Object, required: false },
    url: { type: String, required: false },
    tileText: { type: String, required: false },
  },
  computed: {
    labelLastWord() {
      const n = this.label.trim().split(' ');
      return n[n.length - 1];
    },
    labelWithoutLastWord() {
      return this.label.trim().substring(0, this.label.trim().lastIndexOf(' '));
    },
  },
  methods: {
    routingEvent() {
      if (this.path) {
        this.$router.push({ name: this.path });
      } else {
        window.open(this.url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.homepage-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 600px;
  width: calc(33.33% - 27px);

  @media only screen and (max-width: 1130px) {
    width: calc(50% - 20px);
  }

  @media only screen and (max-width: 750px) {
    margin: 0 auto;
    width: 100%;
  }

  &__text {
    padding: 5px 15px 0 15px;
    p {
      margin-top: 11px;
    }
    &__label {
      font-size: 24px;
      line-height: 1.5rem;
    }
    h2,
    h3 {
      margin: 0;
      line-height: 1.5rem;
    }
  }

  &__mobile-app-cta {
    margin-top: 18px;
    margin-right: 5%;
    & ~ .homepage-card__mobile-app-cta {
      margin-right: 0;
    }
    img {
      height: 40px;
      max-width: 100%;
      width: auto;
    }
  }

  &__image {
    img {
      border-radius: 8px;
      width: 100%;
    }
  }

  &:hover {
    img {
      box-shadow: 0 20px 15px -2px #ccc;
      transition: box-shadow 0.1s ease-in-out;
    }
  }

  &__cta {
    margin-top: 18px;
    margin-right: 5%;
    & ~ .homepage-card__cta {
      margin-right: 0;
    }
    img {
      height: 40px;
      width: auto;
    }
  }
}
.homepage-card:nth-of-type(2n + 1) {
  @media (min-width: 1130px) {
    padding-top: 57px;
  }
}
</style>
