<template>
  <div class="home" :class="{ 'ie-eleven': isIE11 }">
    <h1 class="sr-only">Electrify America Home Page</h1>
    <!-- Use of this pixel is subject to the Amazon ad specs and policies at http://www.amazon.com/b/?&node=7253015011 -->
    <img
      height="1"
      width="1"
      border="0"
      alt
      src="https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D1ba54650-bb4f-9741-9fdf-697694b30237%26type%3DUNKNOWN%26m%3D1&ex-fch=416613&ex-src=https://www.electrifyamerica.com/index&ex-hargs=v%3D1.0%3Bc%3D6544328470501%3Bp%3D1BA54650-BB4F-9741-9FDF-697694B30237"
    />
    <section class="secondaryBG">
      <HeroWithContentOverlay :maxWidth="1920" fullWidthMobile>
        <template #image>
          <img
            v-if="!isPhone && !isMobile"
            alt=""
            src="../assets/images/Home/mainImg.webp"
            style="width: 100%; height: 100%; border-radius: 10px"
          />
          <img
            v-if="isMobile && !isPhone"
            alt=""
            src="../assets/images/Home/mainImg_tablet.webp"
            style="width: 100%; height: 100%; border-radius: 10px"
          />
          <img
            v-if="isPhone"
            alt=""
            src="../assets/images/Home/mainImg_phone.webp"
            style="width: 100%; height: 100%; border-radius: 0"
          />
        </template>
        <h2 class="headline-1">
          Find charging <br v-if="isPhone" />
          <span>fast</span>
        </h2>
        <p>
          Explore the largest public fast charging network in the U.S. — letting you charge in as little as 30 minutes
        </p>
        <form class="search-container" @submit.prevent="goToLocate()" role="search">
          <input class="search-input" placeholder="ENTER CITY OR ZIP" v-model="searchParam" type="search" />
          <button class="green-circle" aria-label="Submit" type="submit" />
        </form>
      </HeroWithContentOverlay>
      <h2 class="sr-only">Total Number of Chargers</h2>
      <div class="charger-count">
        <img loading="lazy" src="../assets/images/Home/charger_icon.svg?external" alt="" />
        <div>
          <span>4,250+</span>
          <h3>Fast chargers in North America</h3>
        </div>
        <img loading="lazy" src="../assets/images/Home/charger_location_icon.svg?external" alt="" />
        <div>
          <span>950+</span>
          <h3>Stations in North America</h3>
        </div>
      </div>
    </section>
    <section class="home__container">
      <div class="middle-section">
        <h2>
          Learn about charging with <br />
          Electrify America
        </h2>
        <div class="l-three-col l-three-col--tablet-two">
          <HomepageCard
            path="mobile-app-en"
            label="Mobile App "
            :src="require('@/assets/images/Home/mobile-app.png')"
            alt="Find Electrify America charging stations using your smart phone"
            tileText="Mobile app tile"
          >
            <p>
              Find stations, start charging, track and end your session from your smartphone with our app for iOS and
              Android.
            </p>
            <a
              class="homepage-card__cta"
              rel="noopener"
              target="_blank"
              href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8"
            >
              <img
                loading="lazy"
                alt="Download on the App Store"
                :src="require('@/assets/images/Global/app-store-logo@2x.webp')"
              />
            </a>
            <a
              class="homepage-card__cta"
              rel="noopener"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
            >
              <img
                loading="lazy"
                alt="Get it on Google Play"
                :src="require('@/assets/images/Global/google-play-badge@2x.webp')"
              />
            </a>
          </HomepageCard>

          <HomepageCard
            path="pricing-en"
            label="Become a member "
            :src="
              !isMobile
                ? require('@/assets/images/Home/member_desktop.png')
                : require('@/assets/images/Home/member_phone.png')
            "
            alt="Woman using her phone to pay at an Electrify America charging station"
            tileText="Become a member tile"
          >
            <p>Need to fill up on the road? Enjoy discounted pricing with our Pass+ monthly subscription plan.</p>
          </HomepageCard>

          <!-- NOTE: THIS IS FOR WINTER CHARGING UNTIL FEB 23/24 -->
          <HomepageCard
            v-if="winterCharging"
            path="mobile-faq-en"
            :pathQuery="{ loadOuter: 0, loadInner: 13 }"
            label="Cold Weather Charging Tips"
            :src="require('@/assets/images/Home/winter-charging.png')"
            alt="5 Tips to Stay Charged This Winter. Be prepared for charging this winter. Learn More"
            tileText="Cold weather tile"
          >
            <p>
              As temperatures begin to drop, here are some tips to consider when planning a winter road trip in your
              electric vehicle.
            </p>
          </HomepageCard>
          <HomepageCard
            v-else
            path="renewable-energy-en"
            label="Renewable Energy &amp; Sustainability "
            :src="require('@/assets/images/Home/electrify-america-solar-glow-1.webp')"
            alt="A picture of the renewable energy farm. A sign says Solar Glow 1."
            tileText="Renewable energy tile"
          >
            <p>
              Electrify America Solar Glow&trade; 1, our first solar farm, is now operational in Southern California.
              Additionally, every time you charge with us on our DC Fast Charging network, the energy delivered to your
              vehicle is now backed by 100% renewable energy via renewable energy certificates.
            </p>
          </HomepageCard>

          <HomepageCard
            path="our-values-en"
            label="OUR VALUES "
            :src="
              !isMobile
                ? require('@/assets/images/Home/our-values.webp')
                : require('@/assets/images/Home/our-values-mobile.webp')
            "
            alt="Four people walking in pairs of two towards the camera crossing a street, each pair is looking towards each other and smiling."
            tileText="Our values tile"
          >
            <p>
              Explore our impact on the environment, our community, and people to create accessible and sustainable
              electric transportation.
            </p>
          </HomepageCard>

          <!-- NOTE: THIS IS FOR WINTER CHARGING UNTIL FEB 23/24 -->
          <HomepageCard
            v-if="winterCharging"
            path="renewable-energy-en"
            label="Renewable Energy &amp; Sustainability "
            :src="require('@/assets/images/Home/electrify-america-solar-glow-1.webp')"
            alt="A picture of the renewable energy farm. A sign says Solar Glow 1."
            tileText="Renewable energy and sustainability tile"
          >
            <p>
              Electrify America Solar Glow&trade; 1, our first solar farm, is now operational in Southern California.
              Additionally, every time you charge with us on our DC Fast Charging network, the energy delivered to your
              vehicle is now backed by 100% renewable energy via renewable energy certificates.
            </p>
          </HomepageCard>
          <HomepageCard
            v-else
            path="contact-us-en"
            label="Support & Troubleshooting "
            :src="require('@/assets/images/Home/support_phone.png')"
            alt="A worker prepares to charge an electric bus using an Electrify America charger"
            tileText="Support and troubleshooting tile"
          >
            <p>
              Have a question or issue with a charging station? Our team is here to help. Call us at 1-833-632-2778 or
              use our contact form.
            </p>
          </HomepageCard>
          <HomepageCard class="contact-less" label="Contactless In-app Payment">
            <p>Download the Electrify America mobile app to pay for your charging session right from your phone.</p>
            <a
              href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8"
              rel="noopener"
              target="_blank"
            >
              <img
                loading="lazy"
                :src="require('@/assets/images/Global/app-store-logo@2x.webp')"
                alt="Download on the App Store"
                class="apple"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
              rel="noopener"
              target="_blank"
            >
              <img
                loading="lazy"
                :src="require('@/assets/images/Global/google-play-badge@2x.webp')"
                alt="Get it on Google Play"
                class="google-play"
              />
            </a>
          </HomepageCard>
        </div>
      </div>
      <HeroWithContentOverlay
        :mobileResponsive="isPhone ? 'below' : 'overlay'"
        :maxWidth="1920"
        :maxContentWidth="!isPhone ? 644 : unset"
      >
        <template #image>
          <img
            loading="lazy"
            v-if="isMobile && !isPhone"
            src="../assets/images/Home/about_us_tablet.png"
            alt="Two Electrify America charging stations glowing green at night"
          />
          <img
            loading="lazy"
            v-if="isPhone"
            alt="Two Electrify America charging stations glowing green at night"
            src="../assets/images/Home/about_us_phone.png"
          />
          <img
            loading="lazy"
            v-if="!isPhone && !isMobile"
            src="../assets/images/Home/about_us.png"
            alt="Two Electrify America charging stations glowing green at night"
          />
        </template>
        <h2>
          <router-link :to="{ name: 'about-us-en' }" class="button-link">
            About Us <arrowWhite class="arrow" style="margin-left: 10px"></arrowWhite>
          </router-link>
        </h2>
        <h3>We're leading the charge of fast charging</h3>
        <p>
          We’re building out a convenient, reliable, customer-centric network of electric vehicle chargers nationwide—at
          workplaces, in communities, and on highways.
        </p>
      </HeroWithContentOverlay>
    </section>
  </div>
</template>

<script>
import AOS from 'aos';
import arrowWhite from '@/assets/images/Home/Arrow_White.svg';
import HomepageCard from '@/components/HomepageCard/HomepageCard.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import timestamp from '../helpers/timestamp';

export default {
  name: 'home',
  components: {
    arrowWhite,
    HomepageCard,
    HeroWithContentOverlay,
  },
  metaInfo: {
    title: 'Electrify America: U.S. EV public charging network | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'The leader in electric vehicle (EV) charging. Electrify America offers the most public fast charging stations in the U.S., plus  commercial products.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/' }],
  },
  data() {
    return {
      searchParam: null,
      winterCharging: timestamp.get() <= 1708750799000,
    };
  },
  methods: {
    goToLocate() {
      if (this.searchParam) {
        const zoom = window.innerWidth >= 1200 ? 5 : 4;

        this.$store.commit('map/resetFilters');
        this.$store.commit('map/setIsFilterPanelExpanded', false);
        this.$store.commit('map/setIsStationDetailExpanded', false);
        this.$store.commit('map/setIsStationListExpanded', false);
        this.$store.commit('locations/setUniqueLocation', null);
        this.$store.commit('locations/setUniqueRoute', null);
        this.$store.dispatch('map/updateMapZoomAndCenter', {
          zoom,
          center: { lat: 38, lng: -96 },
        });
        this.$router.push({ name: 'locate-charger-en', query: { search: this.searchParam } });
      } else {
        this.$router.push({ name: 'locate-charger-en' });
      }
    },
  },
  computed: {
    isIE11() {
      return navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
  mounted() {
    setTimeout(() => {
      AOS.refresh();
    }, 300);
  },
};
</script>

<style scoped lang="scss">
.home {
  padding: 0px 0 60px 0;
  display: flex;
  flex-direction: column;
  font-weight: $fw-regular;
  position: relative;

  @media (min-width: 750px) {
    padding-bottom: 0;
  }
  &__container {
    padding-bottom: 60px;
    @media only screen and (min-width: 750px) {
      padding: 0 40px 60px;
    }
  }
  .l-three-col {
    justify-content: flex-start;
  }
  .apple {
    width: 124.5px !important;
  }
  .google-play {
    width: 134px;
    margin-left: 10px;
    height: 40px;
  }
}
.secondaryBG {
  background-color: $c-secondary-background;
  @media (min-width: 750px) {
    padding: 36px 36px 52px;
  }
}

.disclaimer {
  line-height: 1;
  font-size: 13px;
  padding-top: 30px;
}

.middle-section {
  padding: 60px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  max-width: $site_max_width;
  margin: 0 auto;
  z-index: 2;
  background-color: $c-primary-background;

  @media (min-width: 950px) {
    padding: 60px 100px 44px 100px;
  }

  @media (min-width: 750px) {
    padding: 60px 50px 44px 50px;
  }

  h3 {
    letter-spacing: 0.02em;
    line-height: 36px;
    font-family: 'Ubuntu';
    font-weight: 600;
    max-width: 504px;
    font-size: 24px;
    margin-bottom: 60px;

    @media only screen and (min-width: 1130px) {
      font-size: 32px;
    }
    @media only screen and (max-width: 750px) {
      font-size: 20px;
      line-height: 34px;
      width: 100%;
      margin: 0 auto 34px;
      max-width: 600px;
    }
  }

  .middle-grid {
    column-gap: 40px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 60px;
    margin: 0;
    padding-bottom: 42px;

    @media (min-width: 750px) {
      padding: 60px 50px 44px;
    }

    @media (min-width: 1130px) {
      .homepage-card:nth-of-type(2n + 1) {
        padding-top: 57px;
      }
    }
  }
}
.hero-with-overlay {
  h3 {
    letter-spacing: 2px;
    margin: 0;
    margin-top: 10px;
  }
}

.secondaryBG {
  .hero-with-overlay {
    h1 {
      font-weight: $fw-regular;
      color: $c-primary-background;
      @media (min-width: 750px) {
        font-size: 40px;
      }
      @media (min-width: 1030px) {
        font-size: 48px;
      }
      span {
        font-style: italic;
        font-weight: $fw-medium;
      }
    }
    ::v-deep &__content {
      top: 68%;
      @media (min-width: 750px) {
        top: 55%;
      }
    }
    p {
      @media (min-width: 1030px) {
        font-size: 24px;
        letter-spacing: 1.2px;
      }
    }
  }
}

.charger-count {
  z-index: 1;
  display: grid;
  max-width: 850px;
  grid-template-columns: 0.5fr 1fr 0.5fr 1fr;
  gap: 40px;
  flex-wrap: wrap;
  padding: 40px 40px;
  margin: 0 auto;
  text-align: center;
  align-self: center;
  justify-self: center;
  @media only screen and (max-width: 800px) {
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
  }
  @media only screen and (max-width: 1012px) {
    gap: 0px;
  }
  img {
    width: 54px;
    height: 53px;
    margin: auto;
    @media only screen and (max-width: 800px) {
      width: 42px;
      height: 40px;
    }
  }
  span {
    font-size: 32px;
    font-weight: $fw-light;
    letter-spacing: 1.6px;
    @media only screen and (max-width: 1012px) {
      font-size: 28px;
    }
    @media only screen and (max-width: 800px) {
      font-size: 18px;
    }
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.44px;
    @media only screen and (max-width: 1012px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 800px) {
      font-size: 14px;
    }
  }
}

.search-container {
  margin-top: 30px;
  background-color: $c-primary-background;
  border-radius: 100px;
  width: 327px;
  height: 55px;
  position: relative;
  @media only screen and (min-width: 750px) {
    width: 375px;
  }
  .search-input {
    position: absolute;
    top: 16px;
    left: 20px;
    height: 26px;
    border: none;
    font-family: $ff-primary;
    font-weight: $fw-medium;
    font-size: 13px;
    width: 200px;
  }
  .green-circle {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    border-radius: 100px;
    border: solid 1px $c-highlights;
    background: $c-highlights url('~@/assets/images/Home/Union.svg?external') no-repeat center left 16px;
    top: 8px;
    cursor: pointer;
    &:hover {
      background-color: $c-focus;
      border: 0;
    }
  }

  input[type='search'] {
    padding: 0;
  }
}

// ie11 fixes
.ie-eleven {
  .middle-section {
    width: 100%;
  }

  @media only screen and (min-width: 750px) {
    .middle-section {
      min-height: 982px;
    }
  }
}
</style>
